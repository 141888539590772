.team-person {
  position: relative;
  padding: 10px;

  @media (max-width: $screen-tablet) {
    &:first-of-type {
      margin-top: 44px;
    }
  }

  .person_overlay_close{
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 18px;
  }

  .person-image {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .person-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    display: none;
    transition: .5s ease;
    background-color: $pink-purple;
    z-index: 1000;
    padding: 20px;

    .person-info {
      height: 100%;
      width: 100%;
      p {
        margin-bottom: 15px;
      }

      h4.name {
        color: $purple;
        margin-bottom: 0;
        margin-top: 0;
      }
      a.mail, a.phone {
        color: $dark-grey;
        font-weight: 300;
        line-height: 18px;
      }
      a.mail {
        text-decoration: underline;
      }
    }
  }
}
