#generalterms {
    margin-top: 50px;

    @media (max-width: $screen-tablet) {
        margin-top: 45px;
      }

  .generalterms-read-more {
    display: none;
  }

  .panel {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0;

    .panel-heading {
      background: none;
      border: none;
      padding: 17px 0px;

      @media (max-width: $screen-tablet) {
        padding: 17px 15px;
      }

      .panel-title {
        a {
          text-decoration: none;
        }
        a:before {
          font-family: 'Ionicons';
          content: "\f3d0";
          float: right;
          color: $dark-purple;
          line-height: 13px;
        }
      }
    }

    .panel-heading.active a:before {
      font-family: 'Ionicons';
      content: "\f406";
      float: right;
      color: $dark-purple;
      font-size: 25px;
      line-height: 13px;
      font-weight: bold;
    }

    .panel-body {
      border: none;
      padding: 15px 0px;

      [class*="col-"] {
        padding: 0px;

        @media (max-width: $screen-tablet) {
          padding: 0px 15px;
        }
      }
    }
  }
}
