// Overview page
@media (max-width: $screen-tablet) {
    #page-overview-products {
        .container-fluid {
            margin-top: 183px;
        }
    }
}
.container-fluid {
    padding-left: 0;
    padding-right: 0;

    @media (max-width: $screen-tablet) {
        margin-top: 135px;
    }

    .row {
        margin: 0;
    }

    .product {
        .col-lg-3, .col-md-4, .col-sm-6, .col-xs-12 {
            padding: 0;
        }

        .product-block {
            border-right: 1px solid $light-grey;
            padding-left: 0;

            @media (max-width: $screen-tablet) {
                height: 200px;
                width: 100%;
            }

            .product-image {
                background-color: #f2f2f2;
                height: 340px;
                background: no-repeat top center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;

                @media (max-width: $screen-tablet) {
                    padding: 0;
                    height: 200px;
                    border-bottom: 1px solid $light-grey;
                }
            }

            .info-block {
                background-color: $light-purple;
                padding: 8% 13%;

                @media (max-width: $screen-tablet) {
                    padding: 4%;
                    position: absolute;
                    right: 0;
                    height: 200px;
                    border-bottom: 1px solid $light-grey;
                }

                .info-title h2 {
                    color: $grey;

                    @media (max-width: $screen-tablet) {
                        font-weight: 400;
                        font-size: 22px;
                        text-align: center;
                    }
                }
            }
        }
    }
}