.slick-prev::before{
  content: "\f137";
  font-family: FontAwesome;
  left:-5px;
  position:absolute;
  top:0;
  color: $purple;
}

.slick-next::before {
  content: "\f138";
  font-family: FontAwesome;
  left:5px;
  position:absolute;
  top:0;
  color: $purple;
}

.slick-dots {
  position: absolute;
  bottom: 33px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  li {
    &::before {
      color: $light-grey !important;
    }
    button {
      display: none;
    }
  }
  .slick-active::before {
    color: $white !important;
  }
}
