// typography
// FONT FAMILY
$font-family-default: 'Arial';
$font-family-primary: 'Verdana';

$font-family-icon: 'FontAwesome';

// FONT SIZE
$font-size-h1: 50px;
$font-size-h2: 40px;
$font-size-h3: 40px;
$font-size-h4: 30px;
$font-size-h5: 28px;
$font-size-h6: 22px;
$font-size-p: 16px;

$font_family: "Source Sans Pro", sans-serif;

$font-color: $dark-grey;