// COLORS
$white: #fff;
$black: #000;

//PURPLE
$extra-light-purple: #faf7fb;
$light-purple: #e4dbe8;
$semi-light-purple: #a269b9;
$pink-purple: #f0e7f4;
$purple: #9c5fb5;
$dark-purple: #8a52a5;

//GREY
$very-light-grey: #e5e6e1;
$light-grey: #cacaca;
$grey: #585857;
$dark-grey: #434142;
$mobile-back-grey: #666c70;

// MEDIA QUERIES
$screen-phone: 320px;
$screen-tablet: 768px;
$screen-desktop: 992px;
$screen-large-desktop: 1200px;

// Z-INDEX
$z-index-navigation: 20;
$z-index-hamburger: 21;