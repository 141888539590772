// main

body, html {
  height: 100%;
  margin: 0;
  font-family: $font_family;
  font-size: $font-size-p;
  color: $font-color;
  font-weight: 300;
  line-height: 25px;
}

a {
  color: $purple;
  font-weight: 600;

  &:hover, &:focus, &:active {
    color: $purple;
  }
}

a:hover {
  text-decoration: none;
}

h1 {
  font-weight: 600;
  letter-spacing: 1px;
}

h2 {
  color: #9c5fb5;
  margin-bottom: 35px;
  font-weight: 600;
}

h3, strong {
  font-size: 18px;
  color: #9c5fb5;
  font-weight: 600;
  margin: 0;
  line-height: 21px;
}

p {
  margin: 0 0 25px;
}

.read-more {
  position: absolute;
  bottom: 32px;
  @media (max-width: $screen-tablet) {
    text-align: center;
    position: absolute;
    bottom: 30px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  button {
    background-color: $purple;
    color: $white;
    font-weight: 300;
    padding: 5px 15px;
    border-radius: 15px;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    font-family: $font_family;
  }
}

article table tbody td {
  padding: 10px;
}

article table tbody .oc-cell-thick-border {
  border: 1px solid #e5e6e1;
}
