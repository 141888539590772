// practice equipment

#page-practice-equipment {
  @media (max-width: $screen-tablet) {
  #layout-content {
      margin-top: 138px;
    }
  }
  .header-practice {
    height: 425px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;

    @media (max-width: $screen-tablet) {
        height: 150px;
    }
  }
  #practice-equipment {
    padding: 50px;
    .practice-equipment-list {
      padding-left: 0;
      ul {
        padding-left: 0;
        li {
          list-style-type: none;
          padding-bottom: 20px;
          padding-left: 20px;
          &.active {
            a {
              color: $purple;
              text-decoration: none;
            }
            &:before {
              content: '\f0da';
              color: $dark-grey;
              font-family: $font-family-icon;
              position: absolute;
              font-size: 16px;
              left: 0;
            }
          }
          @media (min-width: $screen-tablet) {
            &:hover {
              a {
                color: $purple;
                text-decoration: none;
              }
              &:before {
                content: '\f0da';
                color: $dark-grey;
                font-family: $font-family-icon;
                position: absolute;
                font-size: 16px;
                left: 0;
              }
            }
          }
          a {
            color: $light-purple;
            font-size: 18px;
          }
        }
      }
    }
  }
}