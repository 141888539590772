.nav-tabs {
  border-bottom: 1px solid $dark-grey;
  margin-top: 30px;

  & > li {
    margin-bottom: 0;
    width: 33.33333%;

    &:nth-child(2) {
      display: inline-block;
      text-align: center;
    }
    & > a {
      border-radius: 0;
      opacity: 0.5;
      text-decoration: none;
      font-weight: 500;
      padding: 0;
      margin-bottom: 20px;

      @media (min-width: 992px) {
        font-size: 23px;
      }
      @media (min-width: 1200px) {
        font-size: 25px;
      }
      @media (min-width: 1350px) {
        font-size: 27px;
      }
      @media (min-width: 1440px) {
        font-size: 30px;
      }
      &:hover, &:focus {
        border-color: transparent;
        background-color: transparent;
        color: $purple;
        opacity: 1;
      }
    }
    &:last-child {
      > a {
        padding-right: 0;
        text-align: right;
      }
    }
    &.active {
      & > a {
        border-color: transparent;
        color: $purple;
        opacity: 1;
        &:after {
          content: '\f0d8';
          color: $dark-grey;
          font-family: $font-family-icon;
          position: absolute;
          bottom: -34px;
          font-size: 25px;
          left: 0;
        }

        &:last-child:after {
          right: -4px;
        }
        &:hover, &:focus {
          border-color: transparent;
          background-color: transparent;
          color: $purple;
          opacity: 0.5;
          cursor: pointer;
        }
        &:focus {
          opacity: 1;
        }
      }
    }
  }
}

.tab-content {
  & > .tab-pane {
    margin: 15px 0 0 0;
    @media (min-width: $screen-desktop) {
      margin-bottom: 30px;
    }
  }
  #contact-information {
    & > .tab-pane {
      span {
        margin-bottom: 10px;
      }
    }
  }
}