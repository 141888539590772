// Page product-info

#product-page {
  background-color: $extra-light-purple;

  .product-container {
    background-color: white;
    padding: 0;
    width: 80%;
    margin-top: 96px;

    @media (max-width: $screen-tablet) {
      width: 100%;
      margin-top: 138px;
    }

    strong {
      color: $purple;
      font-weight: 600;
    }

    .product-header {
      position: relative;

      .product-image {
        padding: 0;
        height: 600px;
        background: no-repeat top center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        @media (max-width: 1600px) {
          height: 600px;
        }
        @media (max-width: 1400px) {
          height: 500px;
        }
        @media (max-width: 1200px) {
          height: 300px;
        }
      }
    }
  }

  .content-container {
    margin-top: 25px;
    padding: 0 150px;
    min-height: 400px;

    @media (max-width: $screen-large-desktop) {
      padding: 75px;
      padding-top: 0;
      min-height: 600px;
    }

    @media (max-width: $screen-tablet) {
      padding: 0;
      margin-top: 0;
    }

    h2 {
      color: $purple;
      margin-bottom: 35px;
      font-weight: 600;

      @media (max-width: $screen-tablet) {
        margin: 35px;
      }
    }

    article {
      margin-bottom: 35px;

      a {
        &:hover {
          color: $light-purple;

        }
      }

      @media (max-width: $screen-tablet) {
        margin: 35px;
      }
    }

    h3 {
      font-size: 16px;
      color: $purple;
      font-weight: 600;
      margin: 0;
    }

    .image-block{
      overflow: hidden;
    }

    .image-block.col-sm-12 {
      height: auto;
      & img{
        width: 100%;
        display: block;
      }
    }

    .image-block.col-sm-6 {
      padding: 0;
      height: 280px;

      & img{
        height: 100%;
        display: block;
      }
    }

    ul {
      position: relative;

      @media (max-width: $screen-tablet) {
        padding-left: 25px;
      }

      li {
        list-style: none;

        h3 {
          color: $grey;
        }

        &::before {
          content: "\f111";
          font-family: "FontAwesome";
          font-size: 8px;
          color: $purple;
          position: absolute;
          left: 0;
        }
      }
    }
    .slider-item {
      height: 280px;
      background: #FFFFFF;
      .slider-item-intern {
        height: 260px;
        margin: 10px;
        overflow: hidden;
        & img {
          margin: 0 auto;
        }
      }
    }
  }
}
