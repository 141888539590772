// navigation
.page-overlay {
  display: none;
  position: fixed;
  z-index: 25;
  background: linear-gradient(
                  rgba(20,20,20, .6),
                  rgba(20,20,20, .6));
  width: 100%;
  height: 100%;
}


.navbar {
  height: 100px;
  border-bottom: 1px solid $light-purple;
  background-color: $white;
  // Animation


  .navbar-overlay {
    height: 100px;
    background: linear-gradient(rgba(20, 20, 20, 0.6), rgba(20, 20, 20, 0.6));
    display: none;
  }

  .navbar-brand {
    padding: 30px;
    height: 100px;
    position: absolute;
    z-index: 18;
  }

  .logo-home {
    display: none;
  }

  #logo-color img {
    &.shrink-logo {
      // Animation
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      height: 95%;
    }
  }

  .title-page {
    color: $dark-grey;
    position: absolute;
    top: 20px;
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    h1 {
      font-weight: 200;
      font-size: 24px;
    }

    h4 a {
      color: black;
      font-weight: 200;
      font-size: 16px;
    }

    @media (max-width: $screen-tablet) {
      background-color: $purple;
      width: 100%;
      top: 88px;
      color: white;

      h1 {
        margin: 0;
        padding: 12px;
        text-align: center;
      }
    }
  }

  .page-back-nav {
    h1 {
      margin: 0;
      margin-top: 8px;

      @media (max-width: $screen-tablet) {
        margin: 0;
      }
    }

    h4 {
      margin-top: 5px;

      @media (max-width: $screen-tablet) {
        background-color: $mobile-back-grey;
        margin: 0;
        padding: 12px 0;
        font-size: 20px;

        a {
          color: white;
          font-weight: 200;
          font-size: 20px;
        }

        span {
          position: absolute;
          left: 5px;
          top: 20px;
        }
      }
    }
  }

  .menu-right {
    float: right;

    .phone-icon {
      display: none;
    }

    .mail-icon, .phone-icon {
      display: block;
      cursor: pointer;
      right: 135px;
      top: 35px;
      position: fixed;

      @media (max-width: $screen-desktop) {
        right: 63px;
        top: 35px;
        display: none;
      }

      .fa-circle {
        color: $purple;
      }

      .fa-envelope {
        font-size: 16px;
        color: $grey;
      }
    }
  }
}

#page-home .navbar .fa-envelope {
  color: $white;
}

.hamburger {
  position: absolute;
  z-index: $z-index-hamburger;
  transition: all .6s ease-in-out;
  top: 25px;
  background-color: $purple;
  right: 0px;
  padding: 17px 20px 15px;

  @media (max-width: $screen-desktop) {
    right: 20px;
    top: 44px;
    padding: 0;
    background-color: transparent;
  }

  &:hover {
    opacity: 1;
  }

  .label-menu {
    color: $white;
    text-transform: uppercase;
    padding-right: 20px;
  }

  .hamburger-box {
    width: 30px;
    height: 17px;

    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      width: 30px;
      height: 2px;
      background-color: $white;
      transition: all .6s ease-in-out;
      position: absolute;

      @media (max-width: 374px) {
        width: 25px;
      }

      @media (max-width: $screen-desktop) {
        background-color: $grey;
      }
    }
    .hamburger-inner {
      &::before {
        top: -9px;
      }
      &::after {
        bottom: -9px;
      }
    }
  }
  &.hamburger-white, &.is-active {
    .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
      background-color: $white;
      transition: all .6s ease-in-out;
    }
  }
  &.is-active {
    position: fixed;
    top: 25px;
    right: 0;
    transition: all .6s ease-in-out;
    background-color: transparent;

    @media (max-width: $screen-desktop) {
      top: 30px;
      right: 20px;
    }

    @media (max-width: 374px) {
      top: 18px;
    }

    .hamburger-inner {
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
  }
  &:focus {
    outline: none;
  }
}

.home .navbar {
  background-color: transparent;
  border-bottom: none;

  .logo-color {
    display: none;
  }

  .logo-home {
    display: block;
  }

  .mail-icon {
    display: block;
  }

  .phone-icon {
    right: 86px;
    display: block;

    @media (max-width: $screen-desktop) {
      right: 110px;
    }
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: $white !important;
  }

  .title-page {
    display: none;
  }
}

#page-contact {
  @media (max-width: $screen-tablet) {
    .title-page {
      display: none;
    }
  }
  @media (min-width: $screen-tablet) {
    .navbar {
      background-color: transparent;
      border: 0;

      &.change-color {
        background: $white;
        border:1px solid $light-purple;
      }

      .title-page {
        display: none;
      }
    }
  }
}

.navigation {
  color: $white;
  background-color: $purple;
  position: fixed;
  width: 19%;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: $z-index-navigation;
  transition: all .6s ease-in-out;
  -webkit-transition: all 0.6s ease;

  &.active {
    right: 0;
    transition: all .6s ease-in-out;
    -webkit-transition: all 0.6s ease;
  }

  &.open-nav {
    right: 19%;

    @media (max-width: $screen-tablet) {
      right: 0;
      z-index: 0;
    }
  }

  &.close-nav {
    right: -100%;
  }

  @media (max-width: $screen-tablet) {
    width: 75%;
  }

  ul {
    padding-left: 0;
    margin-top: 80px;

    @media (max-width: $screen-tablet) {
      margin-top: 60px;
    }

    @media (max-width: 374px) {
      margin-top: 44px;
    }

    li {
      list-style: none;
      padding: 0;
      border-top: 1px solid $dark-purple;
      border-left: 4px solid $purple;

      &:last-child {
        border-bottom: 1px solid $dark-purple;
      }

      &.active, &:hover {
        border-left: 4px solid $white;
      }

      .sub-navigation {
        background-color: $semi-light-purple;
        position: absolute;
        height: 100%;
        margin-top: 0;
        top: 0;
        width: 100%;
        right: -100%;
        padding-top: 80px;
        display: none;
        transition: all .6s ease-in-out;

        @media (max-width: $screen-tablet) {
          &.active {
            display: block;
            z-index: 20;
            right: 0;
            transition: all .6s ease-in-out;
          }
        }

        .back-button-nav {
          position: absolute;
          top: 24px;
          transition: all .6s ease-in-out;
        }

        .close-sub {
          right: -100%;
          transition: all .6s ease-in-out;
        }

        li {
          border-left: 4px solid $semi-light-purple;

          &.active, &:hover {
            border-left: 4px solid $white;
          }
        }
      }

      a {
        font-size: 20px;
        color: $white;
        font-weight: 200;
        letter-spacing: 1px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        display: block;
        text-decoration: none;

        @media (max-width: $screen-tablet) {
          font-size: 18px;
          padding-left: 18px;
          padding-top: 7px;
          padding-bottom: 7px;
        }

        &.active, &:hover {
          border: none;
        }

        &.changed:after {
          font-family: 'FontAwesome';
          content: "\f105";
          color: $white;
          font-size: 20px;
          margin-right: 30px;
          float: right;

          @media (max-width: $screen-tablet) {
            margin-top: 6px;
          }
        }
      }
    }
  }

  .social-icons {
    margin-left: 30px;

    @media (max-width: 374px) {
      display: none;
    }

    @media (max-width: $screen-tablet) {
      margin-left: 18px;
    }
    .fa-stack {
      color: $purple;
      margin: 10px 20px 0 0;

      .fa-circle {
        color: $white;
      }
    }

    @media (max-width: 372px) {
      position: absolute;
      top: 0;

      .fa-stack {
        font-size: 18px;
        margin: 18px 10px 0 0;
      }
    }
  }

  .footer-links {
    padding-left: 35px;
    position: absolute;
    bottom: 30px;

    @media (max-width: 372px) {
      bottom: 0;
    }

    @media (max-width: $screen-tablet) {
      padding-left: 18px;
    }

    .nieuwsbrief-link, .cookieverklaring-link {
      margin-bottom: 10px;

      @media (max-width: 380px) {
        margin-bottom: 0;
      }
    }

    a {
      color: white;
      border-bottom: 1px solid $white;
      font-weight: 200;
      font-size: 15px;
      text-decoration: none;

      @media (max-width: $screen-tablet) {
        font-size: 14px;
      }

      @media (max-width: 372px) {
        font-size: 14px;
      }

      &:active {
        text-decoration: none;
      }
    }

    .voorwaarden-link {
      a:first-child {
        margin-right: 15px;
      }

      @media (max-width: 372px) {
        span {
          font-size: 14px;
        }
      }
    }
  }
}
