
  .modal-header {
    .close {
      font-size: 32px;
    }
    .modal-title {
      color: $purple !important;
      font-size: 26px !important;
    }
  }
  .form-group {
    label {
      color: $purple;
    }
    input[type=text] {
      border-radius: 15px;
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      -o-transition: all 0.30s ease-in-out;
      outline: none;
      padding: 3px 0px 3px 10px;
      margin: 5px 1px 3px 0px;
      border: 1px solid $dark-grey;
    }

    input[type=text]:focus {
      box-shadow: 0 0 5px rgba(156, 95, 181, 1);
      border: 1px solid rgba(156, 95, 181, 1);
    }
  }
