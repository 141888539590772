.sliding-blocks {
  margin: 0;
  padding: 0;
}

.sliding-blocks *, .sliding-blocks *:before, .sliding-blocks *:after {
  box-sizing: border-box;
  position: relative;
}

.content-image {
  background-size: cover;
  background-position: center;
  z-index: 9;
}

@media (min-width: 992px) {
  .sliding-blocks-container {
    position: relative;
    z-index: 0;

    .content-row {
      height: 508px;
      .content-image {
        background-color: black;
      }
      &:nth-child(even) .read-more-block {
        left: -60px;
        background-color: white;
      }
      &:nth-child(odd) .cross {
        right: 62px;
        top: 62px;
      }
      &:nth-child(even) .cross {
        right: 22px;
        top: 22px;
        &.active {
          transform: rotate(45deg);
        }
      }
      &:nth-child(odd) .cross.active {
        transform: rotate(-45deg);
      }
      &:nth-child(even) .content-image,
      &:nth-child(odd) .content-text {
        transform: translateX(100%);
      }
      &.inactive {
        z-index: 100;
        &.left .content-image {
          transform: translateX(0);
        }
        &.right .content-image {
          transform: translateX(100%);
        }
        .content-image {
          overflow: hidden;
          opacity: .5;
          z-index: 12;
        }
        .content-text {
          opacity: 0;
        }
        .read-more-block {
          opacity: 0;
          pointer-events: none;
        }
      }
      .content-image, .content-text {
        position: absolute;
        width: 50%;
        height: 100%;
        z-index: 9999;
      }
      .read-more-block {
        position: absolute;
        transform: rotate(45deg);
        top: 185px;
        right: -60px;
        width: 120px;
        height: 120px;
        background-color: #fff;
        transition: .7s;
        z-index: 20;
        cursor: pointer;
        .cross {
          position: absolute;
          width: 32px;
          height: 32px;
          transition: .7s;
        }
        .cross::before, .cross::after {
          content: '';
          position: absolute;
          left: 15px;
          height: 33px;
          width: 2px;
          background-color: #333;
        }
        .cross::before {
          transform: rotate(-45deg);
        }
        .cross::after {
          transform: rotate(45deg);
        }
      }
      .text-block {
        padding: 100px;
        z-index: 11;
        height: 100%;
        overflow: hidden;
        transition: .7s ease;

        .text {
          p {
            display: none;

            &:first-child {
              display: block;
            }
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }
          img {
            display: none;
          }
        }
      }
    }
  }
  .inactive .content-image, .text-block {
    transition: .7s ease .7s;
  }
  .inactive .text-block, .content-image {
    transition: .7s ease;
  }
}

/* ---------------------- RESPONSIVE ---------------------- */
@media (max-width: 991px) {
  .sliding-blocks-container {
    position: static;
    margin-top: 34px;

    .content-row {
      float: left;
      width: 100%;
      padding-bottom: 20px;
      .content-image, .content-text {
        position: relative;
        float: left;
        width: 100%;
        transform: translateX(0%);
        transition: 0s;
      }
      .content-image {
        height: 170px;
        .read-more-block {
          display: none;
        }
      }
      .content-text {
        border: 0;
        .text-block {
          padding: 0 25px 35px 25px;
        }
        .text {
          overflow: hidden;
          transition: .7s;

          p {
            display: none;

            &:first-child {
              display: block;
            }
          }
          img {
            display: none;
          }
        }
        .mobileReadMore {
          margin: 50px 0 0 0;

          .mobileReadMoreText {
            float: left;
          }
          .openText {
            &.active {
              display: none;
            }
          }
          .closeText {
            display: none;
            &.active {
              display: block;
            }
          }
        }
      }
    }
  }
}