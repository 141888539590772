#page-404 {
  #layout-content, .jumbotron {
    height: 100%;
    padding: 0;
    margin: 0;

    @media (max-width: $screen-desktop) {
      text-align: center;
    }

    .background-img {
      background-image: url('../images/404-background.jpg');
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;

        @media (max-width: $screen-desktop) {
          background-image: url('../images/404-background-tablet.jpg');
        }

        @media (max-width: $screen-tablet) {
          background-image: url('../images/404-background-mob-1.jpg');
        }

      .container {
        padding-top: 15%;
        h1 {
          @media (max-width: $screen-tablet) {
            color: $dark-purple;
            margin-bottom: 55px;
          }
        }

        p {
          @media (max-width: $screen-tablet) {
            color: $white;
            font-weight: 600;
          }

          a {
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
  }
}