// contact - services

#services {
  padding-left: 0;

  .services {
    padding-left: 0;
    position: relative;
    z-index: 20;
    ul {
      padding-left: 0;
      li {
        list-style-type: none;
        padding-bottom: 20px;
        padding-left: 20px;
        &.active, &:hover {
          a {
            color: $purple;
            text-decoration: none;
          }
          &:before {
            content: '\f0da';
            color: $dark-grey;
            font-family: $font-family-icon;
            position: absolute;
            font-size: 16px;
            left: 0;
          }
        }
        a {
          color: $light-purple;
          font-size: 18px;
        }
      }
    }

    .tab-images {
      text-align: center;
    }
  }
  .tab-content {
    position: relative;

    p.hide-text {
      display: none;
    }
  }
}

.services-list {
  ul {
    border: none;
    li {
      display: block;
      width: 100%;
      text-align: left !important;
      list-style-type: none;
      padding-bottom: 0;
      padding-left: 20px;
      &.active {
        a {
          color: $purple;
          text-decoration: none;
          &:after {
            content: none;
          }
        }
        &:before {
          content: '\f0da';
          color: $dark-grey;
          font-family: $font-family-icon;
          position: absolute;
          font-size: 16px;
          left: 0;
          top: -4px;
        }
      }
      a {
        text-align: left !important;
        font-weight: 600;
      }
    }
  }
}