// contact - contact

#page-contact {
  color: $black;

  .header-contact {
    height: 425px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;

    @media (max-width: $screen-tablet) {
      height: 150px;
    }
  }

  .contact-content {
    padding: 0 30px 30px;

    .tab-content {
      .contact-container {
        padding: 0;
        .contact-info {
          padding: 0;

          article {
            margin-bottom: 20px;

            i, strong {
              color: $grey;
            }
          }
          form {
            h3 {
              margin-bottom: 20px;
            }
            .form-group {
              margin-bottom: 20px;

              input[type=text] {
                -webkit-transition: all 0.30s ease-in-out;
                -moz-transition: all 0.30s ease-in-out;
                -ms-transition: all 0.30s ease-in-out;
                -o-transition: all 0.30s ease-in-out;
                outline: none;
                border: 1px solid $dark-grey;
                padding: 3px 0px 3px 10px;
              }

              input[type=text]:focus {
                box-shadow: 0 0 5px rgba(156, 95, 181, 1);
                border: 1px solid $dark-grey;
              }

              .validate-form {
                color: red;
              }

              .form-control {
                border-radius: 15px;
                border: 1px solid $dark-grey;


                &:focus {
                  box-shadow: 0 0 5px rgba(156, 95, 181, 1);
                  border: 1px solid $dark-grey;
                }

                &::-webkit-input-placeholder {
                  color: $dark-grey;
                }
                /* WebKit, Blink, Edge */
                &:-moz-placeholder {
                  color: $dark-grey;
                }
                /* Mozilla Firefox 4 to 18 */
                &::-moz-placeholder {
                  color: $dark-grey;
                }
                /* Mozilla Firefox 19+ */
                &:-ms-input-placeholder {
                  color: $dark-grey;
                }
                /* Internet Explorer 10-11 */
                &::-ms-input-placeholder {
                  color: $dark-grey;
                }
              }
            }
          }
        }
      }
    }
  }
}

#SuperOffice_chatdiv {
  left: 0 !important;
}
