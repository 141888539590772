// buttons
.btn,
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn:active:focus,
.btn-primary:active,
.btn-primary:hover,
&.open > .btn-primary.dropdown-toggle,
&.open > .btn-primary.dropdown-toggle:focus,
&.open > .btn-primary.dropdown-toggle:active,
&.open > .btn-primary.dropdown-toggle:hover{
  background-color: transparent;
  border: 1px solid $light-grey;
  color: $light-grey;
  border-radius: 10px;
  font-weight: 200;
  font-size: 18px;
  text-align: left;
  position: relative;
  letter-spacing: 0px;

  i {
    position: absolute;
    right: 12px;
    top: 10px;
  }
}

.purple-button {
  background-color: $purple;
  color: $white;
  font-weight: 300;
  padding: 5px 15px;
  border-radius: 15px;
  border: none;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: $font_family;

  &:hover, &:focus {
    color: $white;
    background-color: $dark-purple;
    text-decoration: none;
  }
}

//dropdown categories
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.btn-dropdown {
  padding: 0;

  #combo-categories {
    border-radius: 15px;
    -webkit-border-radius: 15px; // for chrome
    border: 1px solid $dark-grey;
    background-color: $white;
    padding: 9px 10px;
    text-align: left;
    z-index: 2;
    color: $grey;

    .fa-chevron-down {
      position: absolute;
      right: 15px;
      top: 13px;
    }
  }

  .dropdown-menu {
    border: 1px solid black;
    border-top: 0;
    border-radius: 0 0 10px 10px;
    padding: 5px 10px;
    z-index: 20;
    top: 30px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: $font-size-p;
    cursor: pointer;
    color: $grey;


    li {
      border-bottom: 1px solid #ccc;
      padding: 5px 0;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover, .active {
        font-weight: 400;
      }
    }
  }
}

.dropdown-backdrop {
  display: none;
}