#product-page {

  .contact-sidebar {
    background-color: $pink-purple;
    height: 100%;
    margin: 0;
    position: absolute;
    right: 0;

    .container {
      width: 80%;
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .contact-text {
    h4 {
      font-weight: 200;
      margin: 0;

      @media (max-width: 1600px) {
        font-size: 16px;
      }
      @media (max-width: 1400px) {
        font-size: 14px;
      }
      @media (max-width: 1200px) {
        font-size: 18px;
      }

      &:first-child {
        color: $purple;
        font-weight: 400;
        margin-top: 20px;
        padding-right: 20px;
      }
    }
  }
  .contact-info {
    margin-top: 20px;
    margin-bottom: 30px;

    h4, h4 a {
      color: $grey;
      font-weight: 200;

      @media (max-width: 1600px) {
        font-size: 16px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      i {
        color: $purple;
        padding-right: 10px;
      }
    }
  }

  .content-container {

    .contact-sidebar-bottom {
      background-color: $pink-purple;
      margin-bottom: 88px;
      margin-top: 30px;
      padding: auto 0;
      display: flex;
      align-items: center;

      @media (max-width: $screen-large-desktop) {
        margin-bottom: 50px;
      }

      @media (max-width: $screen-tablet) {
        background-color: $pink-purple;
        margin-bottom: 0;
        padding: 20px 35px;
        display: block;

        .contact-content {
          padding: 0;
        }
      }

      .vertical-align {
        width: 100%;
        display: flex;
        align-items: center;
      }

      .contact-image, .contact-text, .contact-info, .contact-text h4, .contact-info h4 {
        display: inline-block;
        margin: 0;
      }

      .contact-image {
        padding: 0;
        float: left;

        img {
          width: 70%;

          @media (max-width: $screen-tablet) {
            width: 90%;
          }
        }
      }

      .contact-text {
        margin-right: 0;

        @media (max-width: $screen-large-desktop) {
          float: left;

          h4 {
            display: block;
          }
        }
        @media (max-width: $screen-desktop) {
          margin-top: 15px;
        }

        @media (max-width: $screen-tablet) {
          margin-top: 0;
        }
      }

      .contact-info {
        float: right;

        @media (max-width: $screen-large-desktop) {
          margin-right: 15px;
        }

        @media (max-width: $screen-desktop) {
          float: left;
          margin: 15px 0;

          a {
            padding-right: 10px;
          }
        }

        @media (max-width: $screen-tablet) {
          float: left !important;
          margin-bottom: 0;
        }

        span .fa-circle {
          color: $purple;
        }

        h4 a i {
          padding-top: 10px;
        }
      }
    }
  }
}