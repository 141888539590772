// contact-mobile

#page-contact {

  @media (max-width: $screen-tablet) {
    .header-contact {
      margin-top: 100px;
    }
  }
  #accordion {
    margin: 25px;
    .panel {
      border: 0;
      box-shadow: none;
      border-bottom: 1px solid $dark-grey;
      border-radius: 0;

      .panel-heading {
        background-color: transparent;
        border: 0;
        padding: 20px 0px;

        h1 {
          a {
            color: $purple;
            font-size: 25px !important;
            font-family: $font_family;
            text-decoration: none;

            span {
              color: $dark-grey;
            }
          }
        }
      }
      .panel-body {
        border: 0;
        padding: 0;
        padding-bottom: 25px;

        .contact-container {
          padding: 0;
          .contact-info {
            padding: 0;

            article {
              margin-bottom: 20px;

              i, strong {
                color: $grey;
              }

              & > a {
                text-decoration: underline;
              }
            }
            form {
              h3 {
                margin-bottom: 20px;
              }
              .form-group {
                margin-bottom: 20px;

                input[type=text] {
                  -webkit-transition: all 0.30s ease-in-out;
                  -moz-transition: all 0.30s ease-in-out;
                  -ms-transition: all 0.30s ease-in-out;
                  -o-transition: all 0.30s ease-in-out;
                  outline: none;
                  border: 1px solid $dark-grey;
                }

                input[type=text]:focus {
                  box-shadow: 0 0 5px rgba(156, 95, 181, 1);
                  border: 1px solid $dark-grey;
                }

                .validate-form {
                  color: red;
                }

                .form-control {
                  border-radius: 15px;
                  border: 1px solid $dark-grey;

                  &:focus {
                    box-shadow: 0 0 5px rgba(156, 95, 181, 1);
                    border: 1px solid $dark-grey;
                  }

                  &::-webkit-input-placeholder {
                    color: $dark-grey;
                    line-height: 20px;
                  }
                  /* WebKit, Blink, Edge */
                  &:-moz-placeholder {
                    color: $dark-grey;
                    line-height: 20px;
                  }
                  /* Mozilla Firefox 4 to 18 */
                  &::-moz-placeholder {
                    color: $dark-grey;
                    line-height: 20px;
                  }
                  /* Mozilla Firefox 19+ */
                  &:-ms-input-placeholder {
                    color: $dark-grey;
                    line-height: 20px;
                  }
                  /* Internet Explorer 10-11 */
                  &::-ms-input-placeholder {
                    color: $dark-grey;
                    line-height: 20px;
                  }
                  /* Microsoft Edge */
                }
                #input-notes {
                  height: 78px;
                }
              }
            }
          }
        }
      }
    }
  }
}