//Fullscreen video

video{
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
