// Home

#home-container {
    background-color: black;
    height: 100%;

    .fullscreen-video {
        .overlay-video {
            display: block;
            position: fixed;
            z-index: 9;
            background: linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.2));
            width: 100%;
            height: 100%;
        }

        .mobile-background-home {
            background: url("../../assets/images/mobile-background-home.jpg");
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 8;
            background-size: cover;
        }

        .title-block {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 20;

            .title h1 {
                margin-bottom: 20px;
                color: $white;

                a {
                    color: $white;
                    text-decoration: none;
                }
            }

            .subtitle h2 {
                color: white;
                font-weight: 200;

                a {
                    color: $white;
                    text-decoration: none;
                }
            }

            .separator {
                border-bottom: 1px solid white;
                margin: 0 92px;
            }
        }

        .half-circle  {
            background: url("../../assets/images/smile-line.svg");
            background-repeat: no-repeat;
            width: 100%;
            height: 35%;
            position: absolute;
            bottom: 15px;
            z-index: 20;
        }

        .vgt-block {
            width: 39px;
            height: 39px;
            position: absolute;
            right: 25px;
            bottom: 25px;
            z-index: 20;
        }
    }
}