.pagination {

  li {
    &:first-child > span,
    &:last-child > span {
      border: none;
    }

    & a {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 50%;
      margin: 0 13px;
      color: $purple;
      font-weight: 600;

      @media (max-width: $screen-tablet) {
        margin: 0 5px;
      }

      &:hover,
      &:focus {
        color: $purple;
        background-color: transparent;
        border: 1px solid $grey;
        border-radius: 50%;
      }
    }
    &.active span {
      background-color: transparent;
      border: 1px solid $grey;
      border-radius: 50%;
      margin: 0 13px;
      color: $purple;
      font-weight: 600;

      &:hover, &:focus {
        background-color: transparent;
        border-color: $grey;
        color: $purple;
      }
    }
  }
}