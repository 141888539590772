// contact - downloads

#downloads {
  .download-links {

    @media (max-width: $screen-tablet) {
      .col-xs-12 {
        padding: 0;
      }
    }

    .link-container {
      padding-left: 28px;
      padding-right: 60px;
      background-color: $very-light-grey;
      height: 50px;
      margin: 22px;
      border-radius: 25px;
      background-image: url('../../assets/images/download-icon.png');
      background-repeat: no-repeat;
      background-position-x: right;
      background-position: right 27px top 12px;
      background-position-y: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;

      @media (max-width: $screen-tablet) {
        margin: 10px 0;
        padding-right: 55px;
      }

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: none;

        @media (max-width: 1500px) {
          font-size: 14px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.direct-download {
    h4 {
        font-size: 27px;
        font-weight: bold;
    }

    .downloadRequest {
        margin-top: 40px;
    }
}
