#page-nieuws {
  .sliding-blocks {
    padding-top: 70px;

    @media (max-width: $screen-tablet) {
      padding-top: 0;
    }
  }

  .content-row {
    .content-text {
      .share-article {
        display: none;
        span {
          font-weight: 500;
        }
        .fa-stack {
          color: $white;
          margin-left: 20px;

          .fa-circle {
            color: $purple;
          }
          .fa-inverse {
            position: absolute;
            top: 0;
          }
        }
      }
    }
  }
}
